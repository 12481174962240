import { FC, PropsWithChildren, useEffect, useLayoutEffect } from "react";
import { useUser } from "auth";
import { useHistory, useLocation } from "react-router-dom";
import { SESSION_PATHS } from "Routes/Session/paths";

export const AuthHandler: FC<PropsWithChildren<{ children: any }>> = ({
  children,
}) => {
  const {
    isLoggedIn,
    user,
    profile,
    loading,
    clearSession,
    removeLoginMethod,
  } = useUser();
  const history = useHistory();
  const location = useLocation();
  const hasLoggedInUser = isLoggedIn && !user.isAnonymous;
  const isEmailLink = window.location.search.includes("apiKey=");

  useEffect(() => {
    if (!loading && !hasLoggedInUser && !isEmailLink) {
      (async () => {
        await clearSession();
        removeLoginMethod();
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get("redirect") || location.pathname;

        history.replace(
          redirect && redirect !== "/"
            ? `${SESSION_PATHS.ROOT}?redirect=${redirect}`
            : SESSION_PATHS.ROOT,
        );
      })();
    } else if (!loading && profile?.shouldSetupMFA) {
      history.replace("/signup");
    }
  }, [hasLoggedInUser, clearSession, history, location.pathname, loading]);

  useLayoutEffect(() => {
    if (!loading) {
      document.getElementById("loader")?.remove();
    }
  }, [loading]);

  return !loading && hasLoggedInUser ? children : null;
};
